import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchOrganisationStore = defineStore('searchOrganisationStore', {
  state: () => ({
    BuyerOrganisation_id: null as number | null,
    EncashmentOrganisation_id: null as number | null,
    items: [
      { key: 'BuyerOrganisation_id', operation: ':' },
      { key: 'EncashmentOrganisation_id', operation: ':' }
    ] as TermItemDefinition[]
  })
})
